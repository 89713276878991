import { Center } from "@chakra-ui/react";
import { Flex, Heading, Select } from "@ridepanda/bamboo-ui";
import { useState } from "react";
import { TbBike, TbCloudFog, TbTrees } from "react-icons/tb";
import { useGetActiveRidersMetricsQuery } from "../../api/types-and-hooks";
import { BambooCard } from "../../components/BambooCard";
import { HomeLoader } from "../../components/Loaders";
import { useApp } from "../../contexts/AppContext";
import styles from "./Insights.module.css";
import { ActiveRidersBarChart } from "./components/ActiveRidersBarChart";
import ActiveRidersStat from "./components/ActiveRidersStat";
import { MetricItem } from "./components/MetricItem";

const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const kgFormatter = new Intl.NumberFormat("en-US", {
  style: "unit",
  unit: "kilogram",
});

function formatMetric(value: number | undefined, formatter: Intl.NumberFormat) {
  if (!value) {
    return null;
  }

  return formatter.format(value);
}

function formatEstimated(
  value: number | undefined,
  formatter: Intl.NumberFormat,
) {
  if (!value) {
    return null;
  }

  return `Est. ${formatter.format(value)} by EOY`;
}

export function ActiveRiders() {
  const [selectedServiceAreaId, setSelectedServiceAreaId] = useState("all");

  const { organization } = useApp();
  const serviceAreas = organization?.serviceAreas || [];

  const {
    data: metricsData,
    loading: activeRidersLoading,
    error: activeRidersError,
  } = useGetActiveRidersMetricsQuery({
    variables: {
      serviceAreaId:
        selectedServiceAreaId === "all" ? null : selectedServiceAreaId,
    },
  });

  const currentActiveRidersStats =
    metricsData?.activeRidersMetrics.currentStats;

  const projectedActiveRidersStats =
    metricsData?.activeRidersMetrics.projectedStats;

  const currentActiveRidersDetails = currentActiveRidersStats?.details || [];

  const currentActiveRiders = currentActiveRidersDetails[
    currentActiveRidersDetails.length - 1
  ] || {
    date: new Date(),
    totalCount: 0,
    ebikeCount: 0,
    escooterCount: 0,
    pedalBikeCount: 0,
  };

  const percentageOfRiders = (categoryTotal: number) => {
    if (currentActiveRiders.totalCount === 0) {
      return "0%";
    }

    return `${Math.round(
      (categoryTotal / currentActiveRiders.totalCount) * 100,
    )}%`;
  };

  return (
    <>
      <Flex mt="48" justify="between" align="center">
        <Heading size="24" weight="bold">
          Active Riders & Estimated Impact
        </Heading>
        <Select
          size="sm"
          value={selectedServiceAreaId}
          onValueChange={setSelectedServiceAreaId}
          options={[
            {
              label: "All Service Areas",
              value: "all",
            },
            ...serviceAreas
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((serviceArea) => ({
                label: serviceArea.name,
                value: serviceArea.id,
              })),
          ]}
        />
      </Flex>

      <BambooCard mt="16" className={styles.ActiveRiders}>
        <Flex p="32">
          {activeRidersLoading && <HomeLoader minH={265} w="full" />}
          {!activeRidersLoading && currentActiveRidersDetails.length > 0 && (
            <ActiveRidersBarChart dataset={currentActiveRidersDetails} />
          )}
          {!activeRidersLoading && currentActiveRidersDetails.length === 0 && (
            <Center minH={265} w="full">
              Not enough data to show
            </Center>
          )}
          {activeRidersError && (
            <Center minH={265} w="full">
              There was an error trying to load the data
            </Center>
          )}
        </Flex>
        <Flex className={styles.ActiveRiders__Stats}>
          <ActiveRidersStat
            title="Riders"
            value={
              formatMetric(currentActiveRiders.totalCount, numberFormatter) ||
              "0"
            }
          />
          <ActiveRidersStat
            title="E-Bikes"
            value={percentageOfRiders(currentActiveRiders.ebikeCount)}
          />
          <ActiveRidersStat
            title="E-Scooters"
            value={percentageOfRiders(currentActiveRiders.escooterCount)}
          />
          <ActiveRidersStat
            title="Pedal Bikes"
            value={percentageOfRiders(currentActiveRiders.pedalBikeCount)}
          />
        </Flex>
      </BambooCard>

      <BambooCard mt="12">
        <Flex className={styles.Estimateds_List}>
          <MetricItem
            icon={<TbBike />}
            title="Car trips avoided"
            value={formatMetric(
              currentActiveRidersStats?.carTripsAvoided,
              numberFormatter,
            )}
            estimated={formatEstimated(
              projectedActiveRidersStats?.carTripsAvoided,
              numberFormatter,
            )}
          />
          <MetricItem
            icon={<TbCloudFog />}
            title="Total CO2 offset"
            value={formatMetric(
              currentActiveRidersStats?.totalCo2OffsetKg,
              kgFormatter,
            )}
            estimated={formatEstimated(
              projectedActiveRidersStats?.totalCo2OffsetKg,
              kgFormatter,
            )}
          />
          <MetricItem
            icon={<TbTrees />}
            title="Equal trees planted"
            value={formatMetric(
              currentActiveRidersStats?.treesPlantedEquivalency,
              numberFormatter,
            )}
            estimated={formatEstimated(
              projectedActiveRidersStats?.treesPlantedEquivalency,
              numberFormatter,
            )}
          />
        </Flex>
      </BambooCard>
    </>
  );
}

export default ActiveRiders;
